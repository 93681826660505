import React, { useState } from "react";

const Accordion = (props: any) => {
  const [selected, setSelected] = useState(false);

  function openAccordion() {
    setSelected(!selected);
  }
  return (
    <li className="relative border-b border-gray-200">
      <button
        type="button"
        className="w-full py-4 text-left"
        onClick={openAccordion}
        id={props.accordion?.hashID}
      >
        <div className="flex items-center justify-between">
            {/* Icons: https://flowbite.com/icons/ */}
            { props.accordion.warningIcon !== undefined && props.accordion.warningIcon ? (
                <svg className="h-6 pr-5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
                </svg>) : (<></>)
            }
            { props.accordion.alertIcon !== undefined && props.accordion.alertIcon ? (
                <svg className="h-6 pr-5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>) : (<></>)
            }
            { props.accordion.fireIcon !== undefined && props.accordion.fireIcon ? (
                <svg className="h-6 pr-5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M7.958 19.393a7.7 7.7 0 0 1-6.715-3.439c-2.868-4.832 0-9.376.944-10.654l.091-.122a3.286 3.286 0 0 0 .765-3.288A1 1 0 0 1 4.6.8c.133.1.313.212.525.347A10.451 10.451 0 0 1 10.6 9.3c.5-1.06.772-2.213.8-3.385a1 1 0 0 1 1.592-.758c1.636 1.205 4.638 6.081 2.019 10.441a8.177 8.177 0 0 1-7.053 3.795Z"/>
                </svg>) : (<></>)
            }
            { props.accordion.techniqueIcon !== undefined && props.accordion.techniqueIcon ? (
                <svg className="h-6 pr-5 text-gray-300 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M16.9 9.7 20 6.6 17.4 4 4 17.4 6.6 20 16.9 9.7Zm0 0L14.3 7M6 7v2m0 0v2m0-2H4m2 0h2m7 7v2m0 0v2m0-2h-2m2 0h2M8 4h0v0h0v0Zm2 2h0v0h0v0Zm2-2h0v0h0v0Zm8 8h0v0h0v0Zm-2 2h0v0h0v0Zm2 2h0v0h0v0Z"/>
                </svg>) : (<></>)
            }
            { props.grayTitle ?
                (<span className="font-medium flex-grow text-gray-700 dark:text-gray-400" dangerouslySetInnerHTML={{__html: props.accordion.title}} />) :
                (<span className="font-medium flex-grow dark:text-white" dangerouslySetInnerHTML={{__html: props.accordion.title}} />) }
          {selected ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4 pl-5 dark:text-white"
            >
              <path
                fillRule="evenodd"
                d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4 pl-5 dark:text-white"
            >
              <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </button>

      {selected && (
        <div className="relative overflow-hidden transition-all duration-700" data-aos="zoom-in" data-aos-once="true" data-aos-delay="100">
          <div className="py-2">
            <p className="text-sm text-gray-700 tracking-wide leading-relaxed dark:text-gray-200">
              {props.accordion.docLink && props.accordion.hashID ?
                  <>
                      <span dangerouslySetInnerHTML={{__html: props.accordion.description}}></span>
                      <a href={props.accordion.docLink + "#" + props.accordion.hashID} className={"block mb-5 float-right"}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                               className="size-2 w-6 text-gray-400">
                              <path fillRule="evenodd"
                                    d="M8.914 6.025a.75.75 0 0 1 1.06 0 3.5 3.5 0 0 1 0 4.95l-2 2a3.5 3.5 0 0 1-5.396-4.402.75.75 0 0 1 1.251.827 2 2 0 0 0 3.085 2.514l2-2a2 2 0 0 0 0-2.828.75.75 0 0 1 0-1.06Z"
                                    clipRule="evenodd"/>
                              <path fillRule="evenodd"
                                    d="M7.086 9.975a.75.75 0 0 1-1.06 0 3.5 3.5 0 0 1 0-4.95l2-2a3.5 3.5 0 0 1 5.396 4.402.75.75 0 0 1-1.251-.827 2 2 0 0 0-3.085-2.514l-2 2a2 2 0 0 0 0 2.828.75.75 0 0 1 0 1.06Z"
                                    clipRule="evenodd"/>
                          </svg>
                      </a>
                  </>
                  : <span dangerouslySetInnerHTML={{__html: props.accordion.description}}></span>
              }
            </p>
          </div>
        </div>
      )}
    </li>
  );
};

export default Accordion;

// Components
import React, {useEffect} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import LinksEnum from "../../components/enums/LinksEnum";

function Introduction() {

  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Introduction";
  }, []);

  return (
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="llms">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="LargeLanguageModels" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="explain-llms"
                   data-aos="fade-up"
                   data-aos-delay="100">
                <h1 className="text-bold"><span className="text-header-gradient">Introduction</span></h1>
                  <p>Welcome to The Crafting AI Prompts Framework, your guide to mastering the art of Prompt Engineering. Here, we dive into the details of crafting the best possible prompts, exploring what Prompt Engineering is, and discussing various techniques and strategies.</p>
                  <p>Before we can delve into these topics, it's crucial to understand what Large Language Models (LLMs) are and how they work. This foundational knowledge will help you grasp the concepts and techniques of Prompt Engineering more effectively.</p>
                  <p>In the next section, we will explain what LLMs are, providing you with the essential background needed to fully appreciate the subsequent discussions on Prompt Engineering and the framework we've created.</p>

                  <h2 id="what-is-an-llm"><span className="text-header-gradient">What is</span> an LLM?</h2>
                  <p>A Large Language Model (LLM) is a type of artificial intelligence designed to recognize and generate human-like text based on vast amounts of data. These models are trained using extensive datasets from books, articles, websites, and other text sources to learn language patterns, grammar, context, and semantics. LLMs can perform various tasks such as language translation, text summarization, question answering, and even creative writing. They leverage complex algorithms and deep learning techniques to predict and generate coherent and contextually appropriate text, making them powerful tools for natural language processing applications.</p>
                  <p>LLMs are the foundation behind many well-known Generative AI applications like ChatGPT, Microsoft Copilot, and Google Gemini. These tools are revolutionizing various aspects of our daily lives, and their impressive capabilities are likely what brought you to this guide. By understanding LLMs, you'll gain insights into the technology powering these applications and how to harness their full potential through effective Prompt Engineering.</p>

                  <h2 id="prediction-based"><span className="text-header-gradient">Prediction</span> based</h2>
                  <p>Before understanding how Prompt Engineering works, it's essential to grasp the concept of "predictions." Predictions are at the heart of how Large Language Models (LLMs) like ChatGPT function. LLMs are designed to predict the next token in a sequence based on the context provided by the previous tokens. Tokens can be words, parts of words, or even characters, depending on the specific model and its training. This process involves analyzing vast amounts of text data to learn language patterns and relationships between tokens. When you input a prompt, the model generates a response by predicting each subsequent token, creating coherent and contextually appropriate text.</p>
                  <p>Understanding that LLMs are prediction-based is crucial because it highlights that these models can occasionally make mistakes. Since they rely on probabilities derived from their training data, their predictions might not always be accurate or relevant. This inherent unpredictability underscores the importance of crafting precise and well-structured prompts to guide the model effectively.</p>
                  <p>For a deeper understanding of how predictions work and how LLMs operate, the below video from Code.org offers an excellent explanation. Watching this video will give you a solid foundation before diving into the Crafting AI Prompts Framework.</p>

                  <YoutubeEmbed embedId="X-AWdfSFCHQ" />

                  <p>If the video doesn't work, <a href={"https://www.youtube.com/watch?v=X-AWdfSFCHQ"} rel="noreferrer" target={"_blank"} title={"YOUTUBE: How Chatbots and Large Language Models work"} className={"text-sky-500"}>click here to go to Youtube</a>.</p>

                  <hr />

                  <p>Now that you have a solid understanding of what Large Language Models (LLMs) are and how they work, we can move forward to the exciting world of Prompt Engineering. Ready to continue? Let's dive into the world of Prompt Engineering.</p>

                  <div className={"pt-5"}>
                      <BaseButton url={LinksEnum.PromptEngineeringAnchor} styles="max-w-full px-8 py-4 mr-5 mb-5 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                          Next: Prompt Engineering
                      </BaseButton>
                  </div>
              </div>
        </div>
      </section>
  );
}

export default Introduction;

import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";

interface InnerNav {
    anchor: string,
    title: string,
}
interface SubNavComponentProps {
    anchorRef?: string;
    title: string;
    innerNav?: InnerNav[];
    blockHref?: boolean;
    disabled?: boolean; // Not clickable
    next?: boolean; // To another page
    isMainPage?: boolean; // First page you enter of menu, so on the main url it'll color
}
const SubNavButton: React.FC<SubNavComponentProps> = ({ anchorRef, title, innerNav, blockHref, disabled, next, isMainPage }) => {
    const [selected, setSelected] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    const location = useLocation();

    useEffect(() => {
        setCurrentPath(location.pathname + location.hash);
        if ((location.pathname + location.hash) === anchorRef) { setSelected(true); }
    }, [location.hash + location.hash]);

    function openAccordion() {
        setSelected(!selected);
    }

    function isOnPage(itemAnchorRef?: string) {
        let onPage: boolean = (itemAnchorRef ? currentPath === itemAnchorRef : false);

        if(!onPage) { onPage = isCurrentHashAnchorRef(itemAnchorRef); }
        if (!onPage) { onPage = highlightCurrentMainPageItem(itemAnchorRef); }

        return onPage;
    }

    function highlightCurrentMainPageItem(itemAnchorRef?: string) {
        if (isMainPage) {
            const anchorRefPathname = itemAnchorRef?.split('#')[0];
            return anchorRefPathname === location.pathname && location.hash === "";
        }

        return false; // It's not a mainPage.
    }

    function isCurrentHashAnchorRef(itemAnchorRef?: string) {
        if (itemAnchorRef && Array.from(itemAnchorRef)[0] === "#") {
            return location.hash === itemAnchorRef;
        }
        return false; // It's not a hash anchorRef
    }

    const AccordionIcon = () => {
        return(
            <div className={`transform ${selected ? "" : "rotate-180"}`} onClick={openAccordion}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`h-4`}>
                    <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z" clipRule="evenodd"/>
                </svg>
            </div>
        );
    }

    const NextPageIcon = () => {
        return(<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4">
                <path fillRule="evenodd" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" clipRule="evenodd" />
            </svg>
        );
    }

    const NavItem = () => {
        return(<NavLink
                  className={"cursor-pointer flex col-2 items-center p-2 my-3 transition-colors text-gray-800 rounded-lg dark:text-white hover:bg-[#468ef9] dark:hover:bg-slate-950 hover:border-[#0c66ee] hover:text-white "
                      + (isOnPage(anchorRef) ? "text-white bg-[#468ef9] dark:bg-slate-950" : "")}
                  to={!blockHref && anchorRef ? anchorRef : ""}
                  onClick={!selected ? openAccordion : undefined}>
                    <span className="mx-2 text-sm font-normal flex-grow">{title}</span>
            {innerNav !== undefined ? (
                <AccordionIcon />
            ) : next ? (<NextPageIcon />) : (<></>)}
        </NavLink>);
    }

    const DisabledNavItem = () => {
        return(
            <button className="w-full flex-grow cursor-default flex items-center p-2 my-3 transition-colors text-white rounded-lg dark:text-white dark:bg-slate-950 bg-slate-600 hover:dark:text-white">
            <span className="mx-2 text-small font-normal flex-grow">{title}</span>
        </button>);
    }

    const NavItemFactory = () => {
        return disabled ? <DisabledNavItem /> : <NavItem />
    }

    return(
       <>
           <NavItemFactory />
           <div className={`transition-all ${selected ? 'visible duration-900' : 'invisible hidden duration-900'}`}>
           {
               innerNav?.map((item, index) => {
                   return(
                       <NavLink key={index} className={"flex items-center p-2 my-3 transition-colors text-gray-800 dark:text-white rounded-lg hover:bg-[#468ef9] dark:hover:bg-slate-950 hover:border-[#0c66ee] hover:text-white " + (isOnPage(item.anchor) ? "text-white bg-[#468ef9] dark:bg-slate-950" : "") }
                             to={item.anchor}>
                           <span className="mx-4 text-sm font-normal pl-3">{item.title}</span>
                       </NavLink>
                   );
               })
           }
           </div>
       </>);
}

export default SubNavButton;
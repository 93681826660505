import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import BaseButton from "../../components/base/Button";
import CertificateGenerator from "./certificate";
import axios from "../../libs/axios";
import {useNavigate} from "react-router-dom";
import RoleEnum from "../../components/enums/RoleEnum";
import {Button} from "@headlessui/react";
import Tickets from "../../components/dashboard/dashboard/Tickets";
import TicketStatusEnum from "../../components/enums/TicketStatusEnum";

const Dashboard = () => {
    const { user } = useAuth({ middleware: 'auth' })
    const [canStartQuiz, setCanStartQuiz] = useState(false);
    const [certificateOne, setCertificateOne] = useState(false);
    const [hasAccessRequested, setHasAccessRequested] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [newTicketOpen, setNewTicketOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [showClosedTickets, setShowClosedTickets] = useState(false);

    let navigate = useNavigate();
    const apiCallMadeRef = useRef(false);

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Dashboard"

        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            const tickets = await getUserTickets();
            setTickets(tickets);

            if (tickets) {
                const accessRequestedTicket = tickets.filter(ticket => ticket.category === "REQUEST_TEST_ACCESS");
                setHasAccessRequested(accessRequestedTicket.length > 0);
            }

            const canStartQuizResponse = await getCanStartQuiz();
            setCanStartQuiz(canStartQuizResponse);

            const hasCertificateOneResponse = await getHasCertificateOne();
            setCertificateOne(hasCertificateOneResponse);
        };

        fetchData();
    }, []);

    const getCanStartQuiz = async () => {
        try {
            const response = await axios.get("/quiz/user-can-start");
            return response.data.userCanStartQuiz;
        }catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                navigate('/login');
            }
            return 0;
        }
    }

    const getHasCertificateOne = async () => {
        try {
            const response = await axios.post("/quiz/verify-certificate");
            return response.data;
        }catch(error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                navigate('/login');
            }
            return 0;
        }
    }

    const requestTestAccess = async () => {
        try {
            const response = await axios.post(`/quiz/request-access/`, { user_id: user.id });
            if (response.data.category === "REQUEST_TEST_ACCESS") {
                setHasAccessRequested(true);

                const hasTickets = await getUserTickets();
                setTickets(hasTickets);
            }
            return response.data;
        }catch(error) {
            return 0;
        }
    }

    const getUserTickets = async () => {
        try {
            const response = await axios.get(`/ticket/get/`);
            return response.data;
        }catch(error) {
            return 0;
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/ticket/new', { title, description });
            setTickets(prevTickets => [...prevTickets, response.data]);
            setTitle('');
            setDescription('');
            setNewTicketOpen(false);
        } catch (error) {
            // Handle error
        }
    };

    return(
        user ?
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Dashboard
                </h2>
            }>
            <div className="py-12 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4 flex flex-col md:flex-row">
                    <div className="welcome-section bg-white flex-grow overflow-hidden shadow-sm sm:rounded-lg md:mr-4 mb-4 lg:mb-0">
                        <div className="p-6 bg-white">
                            <h1 id="welcome" className="text-[2rem]">
                                Welcome <span className="text-header-gradient">{user?.name}</span>!
                            </h1>
                            <p>Welcome to your personal dashboard! <br />Here, you can track your achievements in the Crafting AI Prompts Framework.</p>
                        </div>
                    </div>
                    <div className="buttons-section flex flex-col space-y-4">
                        <a href="/documentation/framework" className="button overflow-hidden shadow-sm sm:rounded-lg hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">
                            <div className="p-6 text-sm text-center">
                                <span id="framework" className="text-[1rem] font-bold text-white">Crafting AI Prompts Framework!</span>
                            </div>
                        </a>
                        <a href="/documentation/prompt-techniques" className="button overflow-hidden shadow-sm sm:rounded-lg hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">
                            <div className="p-6 text-sm text-center">
                                <span id="framework" className="text-[1rem] font-bold text-white">Prompt Techniques</span>
                            </div>
                        </a>
                        <a href="/documentation/prompt-injections" className="button overflow-hidden shadow-sm sm:rounded-lg hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 bg-gradient-to-r from-[#468ef9] to-[#0c66ee]">
                            <div className="p-6 text-sm text-center">
                                <span id="framework" className="text-[1rem] font-bold text-white">Prompt Injections</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {(user.role === null) && (
                            <div className="bg-white shadow rounded-md p-8 w-full mx-auto">
                                <div className="animate-pulse flex space-x-4">
                                    <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                                    <div className="flex-1 space-y-6 py-1">
                                        <div className="h-2 bg-gray-300 rounded"></div>
                                        <div className="space-y-3">
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                                                <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                            </div>
                                            <div className="h-2 bg-gray-300 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            (user.role < RoleEnum.DefaultUser) ? (
                                <div>
                                    <div className="assessment-quiz bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                        <div className="p-6 bg-white">
                                            <h1>Crafting AI Prompts <span className="text-header-gradient">Test</span>
                                            </h1>
                                            {
                                                hasAccessRequested !== undefined ?
                                                hasAccessRequested ? (
                                                <>
                                                    <p>You requested access to the Crafting AI Prompts test - Beta. Please wait
                                                        for an admin to accept your request.</p>

                                                    <Tickets tickets={tickets} category={"REQUEST_TEST_ACCESS"} />
                                                </>
                                                ) : (
                                                <>
                                                    <p>Thank you for registering at Crafting AI Prompts! There is a test in
                                                        beta. If you would like to test it, request access with the button
                                                        below.</p>
                                                    <Button
                                                        className={"p-5 text-white bg-gradient-to-r rounded-lg border-rounded bg-green-700 hover:bg-green-800"}
                                                        onClick={() => requestTestAccess()}>Request access</Button>
                                                </>
                                                ) : <><p>Loading...</p></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : user.role < RoleEnum.DefaultUser && (hasAccessRequested === undefined) &&
                                <div>
                                    <div className="assessment-quiz bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                        <div className="p-6 bg-white">
                                            <h1>Crafting AI Prompts <span className="text-header-gradient">Test</span>
                                            </h1>
                                            <p>Loading...</p>
                                        </div>
                                    </div>
                                </div>
                        }
                        {user.role >= RoleEnum.DefaultUser && canStartQuiz && (
                            <div>
                                <div className="assessment-quiz bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                    <div className="p-6 bg-white">
                                        <h1>Crafting AI Prompts <span className="text-header-gradient">Test</span></h1>
                                        <p>It seems like you can { certificateOne.certificate !== "none" ? "retake" : "start"} the Crafting AI Prompts test - level 1. Would you like to start it, click on the button below.</p>
                                        <BaseButton styles={"p-5 text-white bg-gradient-to-r from-[#468ef9] to-[#0c66ee]"} url="/dashboard/quiz">Go to test</BaseButton>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(user.role >= RoleEnum.DefaultUser && certificateOne.certificate !== "none") && (
                            <div>
                                {certificateOne.certificate === "valid" ? (
                                    <CertificateGenerator name={certificateOne?.user} certificateId={certificateOne?.id} dateCompleted={certificateOne?.date} />
                                ) : certificateOne.certificate === "in-progress" ? (
                                    <div className="certificate bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                        <div className="p-6 bg-white">
                                            <h1><span className="text-header-gradient">Certification</span> information</h1>
                                            <p>It seems like you've currently a <span className="text-sky-700 font-bold">test in progress</span>! Make sure to keep at the test window and do not close it.</p>
                                            <p>If you did close it, please reach out to support. Or wait until the time runs out and come back for your result.</p>
                                        </div>
                                    </div>
                                ) : certificateOne.certificate === "failed" && (
                                    <div className="certificate bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                        <div className="p-6 bg-white">
                                            <h1><span className="text-header-gradient">Certification</span> information</h1>
                                            <p>You tried the level 1 - test at <span className="text-sky-700 font-bold">{certificateOne?.date}</span> but <span className="text-red-700 font-bold">failed</span>.<br />
                                            You received a score of <span className="text-red-700 font-bold">{certificateOne?.score}%</span>.</p>
                                            <p>You can try again on <span className="text-sky-700 font-bold">{certificateOne?.retake_date}</span>.</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {user.role >= RoleEnum.Registered && (
                            <div>
                                <div className="assessment-quiz bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                    <div className="p-6 bg-white">
                                        <div className="flex justify-between">
                                            <h2 className="mr-8">Tickets</h2>
                                            <div className={"mt-1"}>
                                                {
                                                    showClosedTickets === true ?
                                                        (<Button className={"p-2 mr-4 text-sm h-auto text-white bg-slate-800"} onClick={() => {setShowClosedTickets(false)}}>Hide closed tickets</Button>) :
                                                        (<Button className={"p-2 mr-4 text-sm h-auto text-white bg-slate-800"} onClick={() => {setShowClosedTickets(true)}}>Show closed tickets</Button>)
                                                }
                                                {
                                                    // Check if last ticket is closed
                                                    tickets && tickets.filter(ticket => ticket.status !== TicketStatusEnum.Closed).length < 2 &&
                                                    <>
                                                        {newTicketOpen ?
                                                            (<Button className={"p-2 text-sm h-auto text-white bg-sky-800"} onClick={() => setNewTicketOpen(false)}>Cancel ticket</Button>)
                                                            : (<Button className={"p-2 text-sm h-auto text-white bg-sky-800"} onClick={() => setNewTicketOpen(true)}>Create ticket</Button>)
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {newTicketOpen ? (
                                                <div>
                                                    <p>Please note: you can only open 2 tickets.</p>
                                                    <form onSubmit={onSubmit} className="mt-4">
                                                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                                                        <input
                                                            id="title"
                                                            type="text"
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            className="mt-1 block w-full rounded-md bg-gray-100 p-3 border-transparent border focus:border-gray-500
                                                            focus:bg-white focus:ring-0"
                                                        />
                                                        <label htmlFor="description" className="block mt-4 text-sm font-medium text-gray-700">Description</label>
                                                        <textarea
                                                            id="description"
                                                            value={description}
                                                            onChange={(e) => setDescription(e.target.value)}
                                                            className={`mt-1 block w-full rounded-md bg-gray-100 p-5 border-transparent border focus:border-gray-500 focus:bg-white focus:ring-0`}
                                                        />
                                                        <button type="submit" disabled={!title || !description}
                                                                className={`mt-4 ${(!title || !description) ? 'bg-gray-300 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'} px-4 py-2 rounded`}>
                                                            Submit
                                                        </button>
                                                    </form>
                                                </div>
                                        ) :
                                            <div>
                                                {
                                                    showClosedTickets ?
                                                        <>
                                                            <p>Below you can find your last closed tickets.</p>
                                                            <Tickets tickets={tickets}
                                                                     showOnlyStatus={TicketStatusEnum.Closed}
                                                                     showMax={5}
                                                            />
                                                        </> :
                                                        <>
                                                            <p>Below you can find your last open tickets.</p>
                                                            <Tickets tickets={tickets}
                                                                     filterStatus={TicketStatusEnum.Closed}
                                                                     showMax={5}
                                                            />
                                                        </>

                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </AppLayout>
        :
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Authenticating...
                </h2>
            }>
        </AppLayout>
    )
}

export default Dashboard

import React, {useEffect} from "react";
// Layouts
import DefaultLayout from "../layouts/default";
// Components
import ThreePhasesSection from "../components/sections/ThreePhases";
import BackToTop from "../components/base/BackToTop";
import HeaderBanner from "../components/sections/HeaderBanner";

function Blogs() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Blogs"
  }, []);

  return (
    <div className="Blogs dark:bg-slate-900 dark:text-white">
      <DefaultLayout>
        <HeaderBanner title="Blogs"
                      buttons={[
                        {title: "Blogs", url: "/blogs#blogs"}
                      ]}
        />

        {/* <!-- Blogs section --> */}
        {/*<section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white">*/}
        <section className="relative flex max-w-full sm:mx-6 shadow sm:rounded-2xl overflow-hidden dark:bg-slate-900 dark:text-white">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 text-center dark:text-white"
          data-aos="fade-up">
            <h3 id="blogs">
              Read more <span className="text-header-gradient">blogs</span>!
            </h3>
            <p>
              Next to the framework, there are more blogs regarding the Crafting AI Prompts Framework.
            </p>
            <p>Learn more about prompt techniques, models and architecture. But also find out some awesome and inspiring usecases.</p>
            <div
                data-aos="fade-up"
                className="flex flex-wrap items-center justify-center"
            >
              {[
                {
                  img: "framework-prompt.png",
                  url: "https://www.linkedin.com/pulse/art-prompt-crafting-jeroen-egelmeers",
                  info: "the framework (first blog)",
                },
                {
                  img: "techniquesprompts.png",
                  url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-two-jeroen-egelmeers",
                  info: "predictions and prompting techniques",
                },
                {
                  img: "architectureprompts.png",
                  url: "https://www.linkedin.com/pulse/art-prompt-crafting-part-three-jeroen-egelmeers-eafie/",
                  info: "prompt architecture",
                },
              ].map((item, index) => {
                return (
                    <a key={index} href={item.url} className="icon hover:bg-gray-200 hover:dark:bg-gray-950" target={"_blank"}  rel="noreferrer" style={{"display": "block", "maxWidth": "320px", "padding":"40px 20px"}}>
                      <img
                          src={require(`../assets/img/${item.img}`)}
                          className="sm:w-1/2 lg:w-72 mx-auto"
                          alt={item.info}
                      />
                      <span><span className="grow">Learn more about</span><br /><span className="text-header-gradient"><strong>{item.info}</strong></span></span>
                    </a>
                );
              })}
            </div>
          </div>
        </section>

        <ThreePhasesSection />

        <BackToTop sticky={false} />
      </DefaultLayout>
    </div>
  );
}

export default Blogs;

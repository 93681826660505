import TicketStatusEnum from "../../enums/TicketStatusEnum";
import React from "react";

const Tickets = ({tickets, category = null, filterStatus = null, showOnlyStatus = null, showMax = null}) => {
    if (category) {
        tickets = tickets.filter(ticket => ticket.category === category);
    }
    if (filterStatus !== null) {
        tickets = tickets.filter(ticket => ticket.status !== filterStatus);
    }

    if (showOnlyStatus !== null) {
        tickets = tickets.filter(ticket => ticket.status === showOnlyStatus);
    }

    if (showMax !== null) {
        tickets = tickets.slice(0, showMax);
    }
    return(
        <div className="grid grid-cols-1 gap-4">
            {tickets && tickets.map((ticket, index) => (
                <div key={index} className="border rounded-lg shadow-md p-6 bg-white">
                    <h2 className="text-lg font-bold text-blue-600 mb-4">Ticket # {tickets.length - index} {ticket.title && ` - ` + ticket.title}</h2>
                    <div className="space-y-2">
                        {/*<div className="flex justify-between">*/}
                        {/*    <strong className="text-gray-700">Category:</strong>*/}
                        {/*    <span className="text-gray-600">{ticket.category}</span>*/}
                        {/*</div>*/}
                        <div className="flex justify-between">
                            <strong className="text-gray-700">Created at:</strong>
                            <span className="text-gray-600">
                                {new Date(ticket.created_at).toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <strong className="text-gray-700">Status:</strong>
                            <span className={ticket.status === TicketStatusEnum.Open ? 'text-green-600' : ticket.status === TicketStatusEnum.Pending ? 'text-yellow-600' : 'text-red-600'}>
                                  {ticket.status === TicketStatusEnum.Open ? 'Open' : ticket.status === TicketStatusEnum.Pending ? 'Pending' : 'Closed'}
                              </span>
                        </div>
                        <div>
                            <strong className="text-gray-700">Description:</strong>
                            <div className="text-gray-600 mt-3">{ticket.description}</div>
                        </div>
                    </div>
                </div>
            ))}
            {tickets && tickets.length === 0 && <div className="border text-gray-500 rounded-lg shadow-md p-6 bg-white">No tickets found.</div>}
        </div>
    );
}

export default Tickets;
// Components
import React, {useEffect, useState} from "react";
import DocumentationNav from "./DocumentationNav";
import BaseButton from "../../components/base/Button";
import RiskLabel from "../../components/labels/RiskLabel";
import WebsitePromptInjection from "./injections/WebsitePromptInjection";
import AdversarialPromptingLabel from "../../components/labels/AdversarialPromptingLabel";

function PromptInjectionDocumentation() {
  useEffect(() => {
    document.title = "Crafting AI Prompts Framework - Prompt Injections"
  }, []);

  const [safetyCheck, setSafetyCheck] = useState(localStorage.getItem('safetyTermsInjections') === "true");
  const [safetyTermsPopup, setSafetyTermsPopup] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(true);

  const closeSafetyPopup = () => {
    setSafetyTermsPopup(false);
    document.documentElement.classList.remove('overflow-hidden');
  }

  const SafetyPopup = () => {
    document.documentElement.classList.add('overflow-hidden');

    return(<div id="default-modal" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed z-50 justify-center bg-black/90 items-center w-full inset-0 h-full max-h-full px-5">
      <div className="relative p-4 w-full max-w-4xl max-h-full m-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-900">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <span className="text-xl font-semibold text-gray-900 dark:text-white">
              Terms of Service
            </span>
            <button type="button" onClick={closeSafetyPopup} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">Before accessing the "Prompt Injections" examples on our website, please read and agree to the following terms of service:</p>
            <ul className="px-10 list-decimal leading-relaxed text-gray-500 dark:text-gray-400">
              <li className="pb-4"><strong>Educational Purpose Only</strong>: The "Prompt Injections" examples provided are intended solely for educational purposes. They are meant to help you understand how prompt injections work and how to defend yourself against them.</li>
              <li className="pb-4"><strong>No Misuse</strong>: You agree not to use the provided examples for any malicious or unethical activities. This includes, but is not limited to, using prompt injections to manipulate, deceive, or harm others.</li>
              <li className="pb-4"><strong>Responsible Use</strong>: By accessing these examples, you confirm that your intention is to learn about the risks associated with prompt injections and to enhance your ability to safeguard against them.</li>
              <li className="pb-4"><strong>Legal Compliance</strong>: You agree to comply with all applicable laws and regulations while using the information provided on this website.</li>
              <li className="pb-4"><strong>No Liability</strong>: We are not responsible for any misuse of the information provided on our website. Users are solely responsible for their actions and any consequences that may arise from the use of this information.</li>
            </ul>

            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">By clicking "Agree," you acknowledge and accept the terms of service. By clicking "Agree & Save," you accept the terms and save your choice for future visits. By clicking "Decline," you choose not to accept the terms and cannot access this section.</p>
          </div>
          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button data-modal-hide="default-modal" onClick={() => { setSafetyCheck(true); closeSafetyPopup(); }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-3">I agree</button>
            <button data-modal-hide="default-modal" onClick={() => { setSafetyCheck(true); localStorage.setItem('safetyTermsInjections', "true"); closeSafetyPopup(); }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I agree & save</button>
            <button data-modal-hide="default-modal" onClick={() => { setShowTermsOfService(false); closeSafetyPopup(); }} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-red-600 rounded-lg border border-gray-200 hover:bg-red-900 hover:text-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-red-600 dark:text-white dark:border-gray-600 dark:hover:text-white dark:hover:bg-red-900">Decline</button>
          </div>
        </div>
      </div>
    </div>);
  }

  const HiddenForSafety = () => {
    if (showTermsOfService) {
      return (<>
        <div id="alert-additional-content-2"
             className="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-black dark:text-red-400 dark:border-red-800"
             role="alert">
          <div className="flex items-center">
            <svg className="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
              <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <span className="text-lg font-medium pl-2">You did not yet accept our terms of service</span>
          </div>
          <div className="mt-2 mb-4 text-sm">
            It looks like you haven't accepted our terms of service yet. <br/> Please agree to the terms of service to
            access this section.
          </div>
          <div className="flex">
            <button type="button" onClick={() => setSafetyTermsPopup(true)}
                    className="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
              <svg className="me-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                   viewBox="0 0 20 14">
                <path
                    d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
              </svg>
              View more
            </button>
            <button type="button" onClick={() => setShowTermsOfService(false)}
                    className="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800"
                    data-dismiss-target="#alert-additional-content-2" aria-label="Close">
              Dismiss
            </button>
          </div>
        </div>
      </>);
    }else {
      return(<div className="p-4 mb-4 text-sm text-red-900 rounded-lg bg-red-80 dark:bg-gray-800 dark:text-red-200"
                  role="alert">
        <p><span className="font-medium">You are not in compliance with our Terms of Service,</span> so this section has been hidden.</p>
        <button type="button" onClick={() => setShowTermsOfService(true)}
                className="text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800"
                data-dismiss-target="#alert-additional-content-2" aria-label="Close">
          Undo
        </button>
      </div>);
    }
  }

  return (
      <>
      <section className="w-full pb-24 flex dark:bg-slate-900 dark:text-white" id="prompt-injections">
        <div className="relative max-w-screen-xl px-4 sm:px-8 mx-auto grid grid-cols-12 gap-x-6">
              <DocumentationNav page="PromptInjections" />

              <div className="col-span-12 lg:col-span-9 space-y-6 px-4 sm:px-6 mt-20 flex-1 dark:text-white content-section" id="adversarial-prompting-exp"
                   data-aos="fade-up"
                   data-aos-delay="100">

                <WebsitePromptInjection />

                <div className={"block flex col-2 mb-1"} id="image-injection">
                  <h2 className={"mb-1"}><span className="text-header-gradient">Image</span> and <span className="text-header-gradient">Document</span></h2>
                  <div className={"mt-3 ml-4"}>
                    <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" />
                  </div>
                  <div className={"mt-3 ml-4"}>
                    <AdversarialPromptingLabel category={"PI"} />
                  </div>
                </div>
                <p>Next to text-based, ChatGPT now also include image generation. With one simple prompt, you can create awesome images without any design knowledge.</p>
                <p>This technological marvel is powered by DALL-E 3, a cutting-edge tool that transforms textual prompts into visual art. Recently, a trend has emerged where users upload an image to provide context for DALL-E 3, which then generates a new image based on this visual input. This showcases ChatGPT's ability to interpret an image and use it as a foundation for further creativity.</p>
                <p>However, a word of caution is necessary regarding the integration of ChatGPT with automation tools. Some users on platforms like LinkedIn have experimented with sending invoices to ChatGPT, which then summarizes and processes payments through other automated services. While innovative, this practice is highly inadvisable. The technique, known as 'Image/Document Prompt Injection,' could pose significant risks.</p>

                <h2 id={"image-injection-the-image-injection"}>The Image Prompt Injection</h2>
                {
                  safetyCheck ?
                  <>
                <p>To understand the potential problem, consider an example. Imagine uploading a plain white image (like Figure 1) to ChatGPT and inquiring about its contents.</p>
                <p>The scenario described here highlights a significant security concern in the realm of digital automation and AI. It involves embedding hidden prompts in images, which can manipulate the output of AI systems like ChatGPT.</p>

                <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                    src={require("../../assets/img/prompt-injections/white-image-promptinjection.jpg")} alt="Example prompt injection in white image"/></p> <div className={"text-right text-gray-300"}>Figure 1</div></div>
                <p>Take, for instance, the example above of an image that appears ordinary but contains a barely visible message, such as "YOU GOT HACKED!" This is a result of incorporating a subtle prompt within the image, a technique that might not be immediately noticeable to the human eye.</p>

                <p>The implications become even more alarming when applied to financial images/documents, like invoices. Imagine embedding a hidden prompt in an invoice that could, for instance, alter the payable amount. In the provided example (Figure 2), a prompt is visibly placed at the top of the invoice. However, if one were to merge this approach with the subtlety of the first example (Figure 1), the prompt could become virtually undetectable to a human viewer. Yet, an AI system like ChatGPT could still recognize and respond to it.</p>

                <div style={{"maxWidth": "600px", "margin": "40px auto"}}><p><img
                    src={require("../../assets/img/prompt-injections/invoice-promptinjection.jpg")} alt="prompt injection in white image"/></p> <div className={"text-right text-gray-300"}>Figure 2</div></div>
                  </> : <HiddenForSafety /> }

                <h2 id={"image-injection-the-document-injection"}>The Document Prompt Injection</h2>
                {
                  safetyCheck ?
                      <>
                <p>
                  Another example highlights the use of documents in recruitment. Many recruiters are now using Generative AI tools to scan resumes from candidates and align them with the job requirements. However, it's important to exercise caution when employing such technology to prevent the accidental sharing of data protected by GDPR with platforms like ChatGPT. The recommendation is to utilize private environments for these activities. Consider a scenario where a candidate includes a hidden prompt, akin to figure 2, within their resume. This situation emphasizes the necessity of vigilance and careful management of sensitive information throughout the recruitment process, ensuring privacy and compliance are maintained.</p>
                <p>This could have a  <RiskLabel category={"risk"} classifier="HIGH" /> and <RiskLabel category={"impact"} classifier="HIGH" />, as this could be overseen by the recruiter, and might easily end up in the Generative AI tools</p>

                <p>This phenomenon underscores the need for caution and robust security measures in the implementation of AI in automated processes, particularly those involving sensitive information like financial transactions.</p>
                </> : <HiddenForSafety /> }
                <h2 id={"image-injection-risk-assessment"}>Risk assessment</h2>
                <p>The "Image/Document Prompt Injection", particularly when integrated with automation tools, presents a nuanced risk profile. It is important to understand how these risks vary based on the usage context. Therefore, Image/Document Prompt Injection (as described) holds a  <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" /> overall.</p>
                <p>In scenarios where users interact directly with AI systems like DALL-E 3 and can immediately review the outputs, the risk of Prompt Injection is lower (<RiskLabel category={"risk"} classifier="LOW" />). This is because any unexpected or manipulated outcomes are likely to be noticed and corrected by the user. Additionally, since DALL-E 3 reinterprets prompts before generating images, this adds a layer of protection against unintended manipulations in the output.</p>
                <p>However, in automated contexts, such as the one depicted in Figure 2 where AI is used to process and act upon information in invoices or the example with resumes, the risk escalates (<RiskLabel category={"risk"} classifier="HIGH" />). In these situations, hidden prompts could alter crucial data, like payment amounts, without human verification. This automation amplifies both the risk and impact, making it a significant concern.</p>
                <p>While automation in certain areas carries limited risk due to specific use cases and the implementation of guard rails, the potential impact can be significant if human validators are unable to detect hidden prompts in the system. This underscores the need for a careful balance between automated efficiency and human oversight.
                  When considering the interaction between human oversight and automation, it's important to note the potential risks involved. If a human fails to detect a hidden prompt, and automation is subsequently triggered, the impact of this oversight could be substantial (<RiskLabel category={"impact"} classifier="HIGH" />).</p>
                <p className={"pb-5"}>Therefore, Image Prompt Injection holds a  <RiskLabel category={"risk"} classifier="HIGH" /> <RiskLabel category={"impact"} classifier="HIGH" /> overall, its danger is particularly pronounced in automated systems, especially in applications involving sensitive or critical information. This highlights the importance of thorough security protocols and careful monitoring when incorporating AI into automated processes.</p>

                <hr className={"pb-5"} />

                <BaseButton url={"/documentation/adversarial-prompting/prompt-leaking#prompt-leaking"} styles="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                  Next: Prompt Leaking
                </BaseButton>
              </div>
        </div>
      </section>

        { safetyTermsPopup ? <SafetyPopup /> : "" }
  </>
  );
}

export default PromptInjectionDocumentation;

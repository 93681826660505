import React, { useCallback, useRef, useState, useEffect } from 'react';

interface CertificateProps {
    name: string;
    certificateId: string;
    dateCompleted: string;
}

const CertificateGenerator: React.FC<CertificateProps> = ({ name, certificateId, dateCompleted  }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        setIsLoading(true);
        const img = new Image();
        img.onload = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the background image
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Set up the text
            ctx.font = '200px "Great Vibes", cursive';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Draw the name
            ctx.fillText(name, canvas.width / 2, canvas.height * 0.40);

            ctx.font = '80px "Great Vibes", cursive';
            ctx.fillText(certificateId, canvas.width / 1.446, canvas.height * 0.742);

            ctx.save(); // Save the current state
            ctx.transform(1, 0, -0.2, 1, 0, 0); // Skew the context
            ctx.font = '50px "Poppins"';
            ctx.fillText("Date of completion: " + dateCompleted, canvas.width / 1.7, canvas.height * 0.62);
            ctx.restore(); // Restore the state
        };
        img.src = require("../../assets/img/quiz/crafting_ai_prompts_framework_certificate_caip_1.png");
        setIsLoading(false);
    }, [name]);

    const onButtonClick = useCallback(() => {
        if (!canvasRef.current) return;

        setIsGenerating(true);

        const dataUrl = canvasRef.current.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'certificate-caip-1.png';
        link.href = dataUrl;
        link.click();

        setIsGenerating(false);
    }, []);

    return (
        <>
            {isLoading ? (
                <div className="bg-white shadow rounded-md p-8 w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-gray-300 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-gray-300 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                ) : (
            <div className="assessment-result bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 lg:mb-0 relative">
                {/* Preview certificate */}
                <div className="mx-auto p-6 bg-white" style={{ maxWidth: '37rem' }}>
                    <canvas
                        ref={canvasRef}
                        width={3508}
                        height={2480}
                        className={"font-certificate-name"}
                        style={{
                            width: '100%',
                            maxWidth: '34rem',
                            height: 'auto',
                            aspectRatio: '297/210',
                            fontFamily: 'Great Vibes'
                        }}
                    />

                   <div className="flex flex-col md:flex-row justify-between items-center space-x-0 md:space-x-5 space-y-5 md:space-y-0">
                        <button
                            onClick={onButtonClick}
                            disabled={isGenerating}
                            className="mt-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
                        >
                            {isGenerating ? 'Generating...' : 'Download Certificate'}
                        </button>

                        <p className={"md:pt-5 text-gray-600 text-sm"}>Certificate ID: <br className={"hidden md:block"} /> {certificateId}</p>
                    </div>
                </div>
            </div>)}
        </>
    );
};

export default CertificateGenerator;
import AppLayout from '../../components/dashboard/Layouts/AppLayout'
import {useAuth} from "../../hooks/auth";
import React, {useEffect, useRef, useState} from "react";
import RoleEnum from "../../components/enums/RoleEnum";
import {useNavigate} from "react-router-dom";
import axios from "../../libs/axios";
import {Check, ChevronDown, ChevronUp, Edit} from 'lucide-react';


const AdminTests = () => {
    const { user } = useAuth({ middleware: 'admin' })
    const [questionList, setQuestionList] = useState([]);
    const [openQuestion, setOpenQuestion] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [editedQuestion, setEditedQuestion] = useState({});

    const apiCallMadeRef = useRef(false);
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Crafting AI Prompts Framework - Admin Tests"

        // Get userList from /api/users and store in setUserList
        const fetchData = async () => {
            if (apiCallMadeRef.current) return;
            apiCallMadeRef.current = true;

            try {
                const response = await axios.get("/admin/test/questions/");
                setQuestionList(response.data);

            } catch (error) {
                if (error.response && error.response.status === 403) {
                    navigate('/dashboard/overview');
                }
            }
        };

        fetchData();

    }, []);

    const handleEdit = (questionIndex) => {
        if (editingQuestion === questionIndex) {
            setEditingQuestion(null);
            setEditedQuestion({});
        } else {
            setEditingQuestion(questionIndex);
            setEditedQuestion(questionList[questionIndex]);
        }
    };

    const handleSubmit = (questionIndex) => {
        console.log(editedQuestion);
        setEditingQuestion(null);
        setEditedQuestion({});
    };

    const handleInputChange = (field, value, answerIndex = null) => {
        setEditedQuestion(prev => {
            if (answerIndex !== null) {
                const updatedAnswers = [...prev.test_answer];
                updatedAnswers[answerIndex] = { ...updatedAnswers[answerIndex], [field]: value };
                return { ...prev, test_answer: updatedAnswers };
            }
            return { ...prev, [field]: value };
        });
    };

    const sortQuestionsByCategory = () => {
        const sortedQuestions = [...questionList].sort((a, b) => a.category.localeCompare(b.category));
        setQuestionList(sortedQuestions);
    };

    const sortQuestionsById = () => {
        const sortedQuestions = [...questionList].sort((a, b) => a.id - b.id);
        setQuestionList(sortedQuestions);
    };


    return(
        user && user?.role >= RoleEnum.Admin ?
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Admin Tests Dashboard
                </h2>
            }>
            <div className="py-12 mx-3 lg:mx-0">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <div className="welcome-section bg-white overflow-hidden shadow-sm sm:rounded-lg mb-4 flex-grow">
                                <div className="p-6 bg-white">
                                    <h1 id="welcome" className="text-[2rem]">
                                        Welcome <span className="text-header-gradient">{user?.name}</span>!
                                    </h1>
                                    <p>Here you can edit tests!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {user.role >= RoleEnum.Admin && (
                        <div>
                            <div className="users-overview bg-white overflow-hidden shadow-sm sm:rounded-lg mt-5">
                                <div className="p-6 bg-white overflow-x-auto">
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className={"mb-0"}>Questions: ({questionList.length})</h2>

                                        <div>
                                            <button
                                                onClick={sortQuestionsById}
                                                className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                            >
                                                Sort by ID
                                            </button>
                                            <button
                                                onClick={sortQuestionsByCategory}
                                                className="bg-blue-500 text-sm hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                                            >
                                                Sort by Category
                                            </button>
                                            <button
                                                onClick={() => {}}
                                                className="bg-gray-500 text-sm text-white font-bold py-2 px-4 rounded"
                                                disabled={true}
                                            >
                                                Create New Question
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-4">
                                        {questionList.map((question, questionIndex) => (
                                            <div key={questionIndex} className="bg-white shadow-md rounded-lg p-6 relative pointer">
                                                <div className="flex items-center mb-4 cursor-pointer" onClick={() => setOpenQuestion(openQuestion === questionIndex ? null : questionIndex)}>
                                                    <span className={`text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded 
                                                    ${question.category === "IPE" ? `bg-blue-800` :
                                                        question.category === "PE" ? `bg-green-800` :
                                                            question.category === "ADVERSARIAL" ? `bg-red-800` :
                                                                question.category === "TECHNIQUE" ? `bg-yellow-800` :
                                                                    `bg-gray-800`
                                                    }`}>
                                                      Category: {question.category}
                                                    </span>
                                                </div>
                                                <div className="flex justify-between items-start w-full mb-2">
                                                    <div className="flex-grow pr-8 cursor-pointer" onClick={() => setOpenQuestion(openQuestion === questionIndex ? null : questionIndex)}>
                                                        {editingQuestion === questionIndex ? (
                                                            <input
                                                                type="text"
                                                                value={editedQuestion.question || question.question}
                                                                onChange={(e) => handleInputChange('question', e.target.value)}
                                                                className="text-lg mb-0 font-normal bg-gray-100 rounded p-2 w-full"
                                                            />
                                                        ) : (
                                                            <h2 className="text-lg mb-0 font-normal">{question.question}</h2>
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={() => setOpenQuestion(openQuestion === questionIndex ? null : questionIndex)}
                                                        className="text-gray-500 hover:text-gray-700 transition-colors"
                                                    >
                                                        {openQuestion === questionIndex ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                                                    </button>
                                                </div>

                                                {openQuestion === questionIndex && (
                                                    <div className="mt-4 space-y-2">
                                                        {(editingQuestion === questionIndex ? editedQuestion.test_answer : question.test_answer).map((answer, answerIndex) => (
                                                            <div key={answerIndex} className={`p-3 rounded ${answer.correct_answer ? 'bg-green-100' : 'bg-gray-100'}`}>
                                                                {editingQuestion === questionIndex ? (
                                                                    <input
                                                                        type="text"
                                                                        value={answer.answer}
                                                                        onChange={(e) => handleInputChange('answer', e.target.value, answerIndex)}
                                                                        className="bg-transparent rounded p-1 w-full"
                                                                    />
                                                                ) : (
                                                                    answer.answer
                                                                )}
                                                            </div>
                                                        ))}

                                                        <div className="flex justify-end items-center pt-3">
                                                            {editingQuestion === questionIndex ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => handleEdit(questionIndex)}
                                                                        className="text-sm bg-gray-200 mr-3 hover:bg-gray-300 text-gray-800 py-1 px-3 rounded transition-colors"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleSubmit(questionIndex)}
                                                                        className="text-sm bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded transition-colors"
                                                                    >
                                                                        <Check size={16} className="inline mr-1" />
                                                                        Submit
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <button
                                                                    onClick={() => handleEdit(questionIndex)}
                                                                    className="text-sm bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded transition-colors"
                                                                >
                                                                    <Edit size={16} className="inline mr-1" />
                                                                    Edit
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </AppLayout>
        :
        <AppLayout
            header={
                <h2 className="font-semibold text-xl text-gray-800 leading-tight">
                    Authenticating...
                </h2>
            }>
        </AppLayout>
    )
}

export default AdminTests
